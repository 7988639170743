
import { defineComponent, ref } from "vue";
// import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "widget-1",
  components: {
    // Dropdown3,
  },  
  
});
